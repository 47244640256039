<template>
  <div class="main__content">
    <div class="table__header_bar">
      <InputSearch isFilter @detectSearchValue="searchTableHandler" />
      <Button
        isControl
        iconName="plus"
        class="add__dart_btn"
        @click.native="createDartUserHandler"
      >
        {{ $t('sections.dart_to_register') }}
      </Button>
    </div>
    <Table
      v-if="getTableOfDartAccessAdmin.table"
      :content="getTableOfDartAccessAdmin.table.items"
      @tableHandler="tableHandler"
    />
    <v-skeleton-loader v-else type="table" />
  </div>
</template>

<script>
import Table from '@/elements/Table';
import { mapGetters } from 'vuex';

export default {
  components: {
    Table,
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getTableOfDartAccessAdmin']),
  },
  created() {
    this.$store.dispatch('getTableOfDartAccessAdmin');
  },

  methods: {
    tableHandler() {
      this.$store.dispatch('getTableOfDartAccessAdmin');
    },

    searchTableHandler(val) {
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val });
      this.$store.dispatch('getTableOfDartAccessAdmin');
    },
    createDartUserHandler() {
      this.$router.push({
        name: 'access-administrator_dart-create-user',
        params: { activity: 'create' },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.add__dart_btn
  width: 277px
  height: 48px
</style>
